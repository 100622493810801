/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

/* purgecss start ignore */
@tailwind base;
@tailwind components;

/* purgecss end ignore */
@tailwind utilities;

.ls-overlay {
  align-items: center;
  backdrop-filter: blur(5px);
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 30;
}

.ls-blur-8 {
  backdrop-filter: blur(8px);
}
